exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-about-smart-page-js": () => import("./../../../src/templates/about-smart-page.js" /* webpackChunkName: "component---src-templates-about-smart-page-js" */),
  "component---src-templates-app-list-js": () => import("./../../../src/templates/app-list.js" /* webpackChunkName: "component---src-templates-app-list-js" */),
  "component---src-templates-blog-home-js": () => import("./../../../src/templates/blog-home.js" /* webpackChunkName: "component---src-templates-blog-home-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-charities-and-organisations-js": () => import("./../../../src/templates/charities-and-organisations.js" /* webpackChunkName: "component---src-templates-charities-and-organisations-js" */),
  "component---src-templates-customer-support-package-js": () => import("./../../../src/templates/customer-support-package.js" /* webpackChunkName: "component---src-templates-customer-support-package-js" */),
  "component---src-templates-faq-style-js": () => import("./../../../src/templates/faq-style.js" /* webpackChunkName: "component---src-templates-faq-style-js" */),
  "component---src-templates-feedback-js": () => import("./../../../src/templates/feedback.js" /* webpackChunkName: "component---src-templates-feedback-js" */),
  "component---src-templates-forum-redirect-js": () => import("./../../../src/templates/forum-redirect.js" /* webpackChunkName: "component---src-templates-forum-redirect-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-help-home-js": () => import("./../../../src/templates/help-home.js" /* webpackChunkName: "component---src-templates-help-home-js" */),
  "component---src-templates-help-post-js": () => import("./../../../src/templates/help-post.js" /* webpackChunkName: "component---src-templates-help-post-js" */),
  "component---src-templates-help-search-js": () => import("./../../../src/templates/help-search.js" /* webpackChunkName: "component---src-templates-help-search-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-pricecap-js": () => import("./../../../src/templates/pricecap.js" /* webpackChunkName: "component---src-templates-pricecap-js" */),
  "component---src-templates-register-for-priority-services-page-js": () => import("./../../../src/templates/register-for-priority-services-page.js" /* webpackChunkName: "component---src-templates-register-for-priority-services-page-js" */),
  "component---src-templates-scheduled-top-ups-js": () => import("./../../../src/templates/scheduled-top-ups.js" /* webpackChunkName: "component---src-templates-scheduled-top-ups-js" */),
  "component---src-templates-struggling-to-pay-hub-js": () => import("./../../../src/templates/struggling-to-pay-hub.js" /* webpackChunkName: "component---src-templates-struggling-to-pay-hub-js" */),
  "component---src-templates-warm-home-discount-js": () => import("./../../../src/templates/warm-home-discount.js" /* webpackChunkName: "component---src-templates-warm-home-discount-js" */),
  "component---src-templates-webchat-home-js": () => import("./../../../src/templates/webchat-home.js" /* webpackChunkName: "component---src-templates-webchat-home-js" */),
  "component---src-templates-webchat-redirect-page-js": () => import("./../../../src/templates/webchat-redirect-page.js" /* webpackChunkName: "component---src-templates-webchat-redirect-page-js" */),
  "component---src-templates-winter-calculator-js": () => import("./../../../src/templates/winter-calculator.js" /* webpackChunkName: "component---src-templates-winter-calculator-js" */)
}

